@import '../utilities/variable';

//BASIC LAYOUT STYLES---------------BASIC LAYOUT STYLES-------------BASIC LAYOUT STYLES-------------BASIC LAYOUT STYLES

.tag-display {
  a {
    .tertiary-button {
      background-color: #0d6efd;
    }
  }
}

.tertiary-button {
  //background-color: $blue500;
  //color: $purewhite;
  border: 1px solid $blue500;
  &:hover {
    background-color: $blue500 !important;
    color: $purewhite;
    border: 1px solid $blue500;
  }
}

.secondary-link {
  color: $blue500;
  background-color: $purewhite;
  border: 1px solid $purewhite;
  &:hover {
    background-color: $purewhite;
    color: $blue500 !important;
    text-decoration: none !important;
  }
}



//LB-Level_Q ---------------------------------------------------

.LB-Level-Q {

  .surface-primary {
    background-color: $LBsecondary500 !important;
    border: 1px solid $LBsecondary500 !important;
    color: $blackwhite900 !important;
    &:hover {
      background-color: $LBsecondary600;
      color: $blackwhite900;
    }

    &:active {
      background-color: $LBsecondary700;
      color: $blackwhite900;
    }
  }
  .tenant-top-nav {

    div.search-col.col-xl-8.col-lg-8.col-md-11.col-11 {
      background-color: $LBsecondary500;
    }

    div.signin-col.col-xl-4.col-lg-4.col-md-1.col-1 {
      background-color: $LBsecondary500;

      .btn-primary {
        background-color: $LBsecondary500 !important;
        color: $blackwhite900 !important;
      }
    }
  }

  .tenant-info {
    color: $blackwhite900 !important;

    h5 {
      color: $blackwhite900 !important;
    }

    p {
      color: $blackwhite900 !important;
      border-bottom: 1px solid $LBsecondary500 !important;
    }
  }

  .setting-feedback-login {
    .tenant-select {
      .css-yk16xz-control {
        background-color: $LBsecondary500 !important;
        border: 1px solid $LBsecondary500 !important;

        .css-1uccc91-singleValue {
          color: $blackwhite900 !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $LBsecondary500 !important;
        }

        svg {
          color: $LBsecondary500 !important;
        }
      }
    }

    .support {
      color: $blackwhite900 !important;

      .feedback-link {
        color: $blackwhite900 !important;
      }

      &:hover {
        background-color: $LBsecondary500 !important;
      }
    }

    button {
      color: $blackwhite900 !important;

      &:hover {
        background-color: $LBsecondary600;
        color: $blackwhite900;
      }

      &:active {
        background-color: $LBsecondary700;
        color: $blackwhite900;
      }
    }
  }

  .side-workspace-nav {
    .nav-container {
      button {
        color: $blackwhite900 !important;

        svg {
          color: $blackwhite900 !important;
        }
      }

      .expand-icon {
        color: $text-primary !important;
      }

      .sidebar-expand {
        &:active {
          color: $blackwhite900 !important;
        }
      }

      .sidenav-link {
        color: $blackwhite900;

        &:hover {
          background-color: $LBsecondary600;
          color: $blackwhite900;
        }

        &:active {
          background-color: $LBsecondary700;
          color: $blackwhite900;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $LBsecondary500;
          color: $blackwhite900;
        }
      }

      .support-request {
        border-top: solid $LBsecondary500 1px;
        .support-row {
          color: $blackwhite900;

          &:hover {
            background-color: $LBsecondary600;
            color: $blackwhite900 !important;
          }
  
          &:active {
            background-color: $LBsecondary700;
            color: $blackwhite900;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $LBsecondary500 !important;
    }

    .sidenav-link {
      color: $blackwhite900 !important;

      &:hover {
        background-color: $LBsecondary500 !important;
        color: $blackwhite900 !important;
      }
    }

    .support-request {
      .support-link {
        color: $blackwhite900 !important;

        &:hover {
          background-color: $LBsecondary500;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $blackwhite900;
      }

      button {
        color: $blackwhite900;
      }

      svg {
        color: $LBsecondary500 !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $blackwhite900 !important;
    }
  }

  .preview-link {
    color: $blackwhite900;
  }

  .page-item {
    .page-link {
      color: $blackwhite900;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $LBsecondary500;
      border: none;
      color: $blackwhite900;
    }
  }

  span.report-num {
    color: $blackwhite900 !important;
  }

  .clear-filter-btn {
    color: $blackwhite900 !important;
  }

  .tertiary-button {
    background-color: $LBsecondary500 !important;
    color: $blackwhite900 !important;
    border: 1px solid $LBsecondary500;
    &:hover {
      background-color: $LBsecondary500;
      color: $blackwhite900;
      border: 1px solid $LBsecondary500;
    }
  }
  
  .secondary-link {
    color: $blackwhite900 !important;
    &:hover {
      color: $blackwhite900 !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $blackwhite900;
  }

  .add-new-report {
    &:hover {
      background-color: $LBsecondary500;
    }
  }

  .button-cancel {
    color: $blackwhite900 !important;
  }

  input[type="checkbox"] {
    accent-color: $LBsecondary500 !important;
  }
}







//CEPI CUSTOM COLORS ------------CEPI-------------------CEPI--------------CEPI-------------------CEPI--------
.CEPI {
  .tenant-top-nav {

    div.search-col.col-xl-8.col-lg-8.col-md-11.col-11 {
      background-color: $cepiPrimaryDark;
    }

    div.signin-col.col-xl-4.col-lg-4.col-md-1.col-1 {
      background-color: $cepiPrimaryDark;

      .btn-primary {
        background-color: $cepiPrimaryDark !important;
      }
    }
  }

  .tenant-info {
    color: $cepiPrimaryDark !important;

    h5 {
      color: $cepiPrimaryDark !important;
    }

    p {
      color: $cepiPrimaryDark !important;
      border-bottom: 1px solid $cepiPrimaryDark !important;
    }
  }

  // tenant selector styles---------------------
  .setting-feedback-login {
    .tenant-select {
      .css-yk16xz-control {
        background-color: $blackwhite50 !important;
        border: 1px solid $cepiPrimaryDark !important;

        .css-1uccc91-singleValue {
          color: $cepiPrimaryDark !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $cepiPrimaryDark !important;
        }

        svg {
          color: $cepiPrimaryDark !important;
        }
      }
    }

    .support {
      color: $cepiPrimaryDark !important;

      .feedback-link {
        color: $cepiPrimaryDark !important;
      }

      &:hover {
        background-color: $cepiPrimaryLight !important;
      }
    }

    button {
      color: $cepiPrimaryDark !important;

      &:hover {
        background-color: $cepiPrimaryLight !important;
      }
    }
  }

  //SIDE NAV----------------------------
  .side-workspace-nav {
    .nav-container {
      button {
        color: $cepiPrimaryDark;

        svg {
          color: $cepiPrimaryDark;
        }
      }

      .expand-icon {
        color: $cepiPrimaryDark !important;
      }

      .sidebar-expand {
        &:active {
          color: $cepiPrimaryDark !important;
        }
      }

      .sidenav-link {
        color: $cepiPrimaryDark;

        &:hover {
          background-color: $cepiPrimaryMedium;
          color: $purewhite;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $cepiPrimaryDark;
          color: $purewhite;
        }
        .sidenav-link.active {
          svg {
            color: $purewhite !important;
          }
        }
      }

      .support-request {
        border-top: solid $cepiPrimaryDark 1px;
        .support-row {
          color: $cepiPrimaryDark;

          &:hover {
            background-color: $cepiPrimaryMedium;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $cepiPrimaryDark !important;
    }

    .sidenav-link {
      color: $cepiPrimaryDark !important;

      &:hover {
        background-color: $cepiPrimaryMedium !important;
        color: $purewhite !important;
      }
    }

    .support-request {
      .support-link {
        color: $cepiPrimaryDark;

        &:hover {
          background-color: $cepiPrimaryMedium;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $cepiPrimaryDark;
      }

      button {
        color: $cepiPrimaryDark;
      }

      svg {
        color: $cepiPrimaryDark !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $cepiPrimaryDark !important;
    }
  }

  .preview-link {
    color: $cepiPrimaryDark;
  }


  .page-item {
    .page-link {
      color: $cepiPrimaryDark;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $cepiPrimaryDark;
      border: none;
      color: $purewhite;
    }
  }

  span.report-num {
    color: $cepiPrimaryDark !important;
  }

  .clear-filter-btn {
    color: $cepiPrimaryDark !important;
  }

  .tertiary-button {
    background-color: $cepiPrimaryDark !important;
    color: $purewhite;
    border: 1px solid $cepiPrimaryDark;
    &:hover {
      background-color: $cepiPrimaryDark;
      color: $purewhite;
      border: 1px solid $cepiPrimaryDark;
    }
  }
  
  .secondary-link {
    color: $cepiPrimaryDark !important;
    background-color: $purewhite;
    border: 1px solid $purewhite;
    &:hover {
      background-color: $purewhite;
      color: $cepiPrimaryDark !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $cepiPrimaryDark;
  }

  .add-new-report {
    &:hover {
      background-color: $cepiPrimaryMedium;
    }
  }

  .button-cancel {
    color: $cepiPrimaryDark !important;
  }

  input[type="checkbox"] {
    accent-color: $cepiPrimaryDark !important;
  }
}

// GAVI CUSTOM COLOR SCHEME----------------GAVI-----------------GAVI----------------GAVI-------------GAVI
.Gavi-MST {
  
  .surface-primary {
    background-color: $purewhite;
  }

  .text-secondary {
    color: $blackwhite600;
  }

  .disabled {
    color: $blackwhite500;
  }

  .border-text-secondary {
    border: 1px solid $blackwhite600;
  }

  .surface-brand-primary {
    background-color: $gaviBlue500 !important;
    border: 2px solid $gaviBlue500 !important;
    color: white !important;

    &:hover {
      background-color: $gaviBlue300 !important;
      border: 2px solid $gaviBlue300 !important;
      color: $purewhite !important;
    }

    &:active {
      background-color: $gaviBlue300 !important;
      border: 2px solid $gaviBlue300 !important;
      color: $purewhite !important;
    }
  }

  .btn-primary-lg {
    @include buttonLarge();
    padding: 6px 10px !important;
    border-radius: 0px !important;
    font-weight: 400 !important;
    letter-spacing: .03em !important;
    svg {
      width: 16px;
      height: 16px;
      margin-inline-start: 4px;
      margin-inline-end: 4px;
    }
}
  .tenant-top-nav {
    @media (max-width: 1399px) {
      .search-col {
        padding-block-start: 2px;
      }
    }
    div.search-col.col-xl-8.col-lg-8.col-md-11.col-11 {
      background-color: $gaviBlue500;
    }

    div.signin-col.col-xl-4.col-lg-4.col-md-1.col-1 {
      background-color: $gaviBlue500;
      .btn-primary {
        background-color: $gaviBlue500 !important;
      }
    }
  }

  .tenant-info {
    color: $gaviBlue500 !important;

    h5 {
      color: $gaviBlue500 !important;
    }

    p {
      color: $gaviBlue500 !important;
      border-bottom: 1px solid $gaviBlue500 !important;
    }
  }

  .setting-feedback-login {

    // tenant selector styles
    .tenant-select {
      .css-yk16xz-control {
        background-color: $blackwhite50 !important;
        border: 1px solid $gaviBlue500 !important;

        .css-1uccc91-singleValue {
          color: $gaviBlue500 !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $gaviBlue500 !important;
        }

        svg {
          color: $gaviBlue500 !important;
        }
      }
    }

    .support {
      color: $gaviBlue500 !important;

      .feedback-link {
        color: $gaviBlue500 !important;
        &:hover {
          color: $purewhite !important;
        }
      }

      &:hover {
        background-color: $gaviBlue300 !important;
        color: $purewhite !important;
      }
    }

    button {
      color: $gaviBlue500 !important;

      &:hover {
        background-color: $gaviBlue300 !important;
        color: $purewhite !important;
      }
    }
  }

  //SIDE NAV----------------------------
  .side-workspace-nav {
    .nav-container {
      button {
        color: $gaviBlue500;

        svg {
          color: $gaviBlue500;
        }
      }

      .expand-icon {
        color: $gaviBlue500 !important;
      }

      .sidebar-expand {
        &:active {
          color: $gaviBlue500 !important;
        }
      }

      .sidenav-link {
        color: $gaviBlue500;

        &:hover {
          background-color: $gaviBlue300;
          color: $purewhite;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $gaviBlue500;
          color: $purewhite;
        }
      }

      .support-request {
        border-top: solid $gaviBlue500 1px;
        .support-row {
          color: $gaviBlue500;
          &:hover {
            background-color: $gaviBlue300;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $gaviBlue500 !important;
    }

    .sidenav-link {
      color: $gaviBlue500 !important;

      &:hover {
        background-color: $gaviBlue300 !important;
        color: $purewhite !important;
      }
    }

    .support-request {
      .support-link {
        color: $gaviBlue500;

        &:hover {
          background-color: $gaviBlue300;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $gaviBlue500;
      }

      button {
        color: $gaviBlue500;
      }

      svg {
        color: $gaviBlue500 !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $gaviBlue500 !important;
    }
  }

  .preview-link {
    color: $gaviBlue500;
  }

  }

  .page-item {
    .page-link {
      color: $gaviBlue500;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $gaviBlue500;
      border: none;
      color: $purewhite;
    }
  }

  span.report-num {
    color: $gaviBlue500 !important;
  }

  .clear-filter-btn {
    color: $gaviBlue500 !important;
  }

  .tertiary-button {
    background-color: $gaviBlue500 !important;
    color: $purewhite;
    border: 1px solid $gaviBlue500;
    &:hover {
      background-color: $gaviBlue500;
      color: $purewhite;
      border: 1px solid $gaviBlue500;
    }
  }
  
  .secondary-link {
    color: $gaviBlue500 !important;
    background-color: $purewhite;
    border: 1px solid $purewhite;
    &:hover {
      background-color: $purewhite;
      color: $gaviBlue500 !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $gaviBlue500;
  }

  .add-new-report {
    &:hover {
      background-color: $gaviBlue300;
    }
  }
  .button-cancel {
    color: $gaviBlue500 !important;
  }

  input[type="checkbox"] {
    accent-color: $gaviBlue500 !important;
  }

//BMGF COLOR SYSTEM--------------BMGF------------------BMGF----------------BMGF-----------------BMGF--------
.BMGF {
  .tenant-top-nav {

    div.search-col.col-xl-8.col-lg-8.col-md-11.col-11 {
      background-color: $BMGFblack;
    }

    div.signin-col.col-xl-4.col-lg-4.col-md-1.col-1 {
      background-color: $BMGFblack;

      .btn-primary {
        background-color: $BMGFblack !important;
      }
    }
  }

  .tenant-info {
    color: $BMGFblack !important;

    h5 {
      color: $BMGFblack !important;
    }

    p {
      color: $BMGFblack !important;
      border-bottom: 1px solid $BMGFblack !important;
    }
  }

  .setting-feedback-login {

    // tenant selector styles
    .tenant-select {
      .css-yk16xz-control {
        background-color: $blackwhite50 !important;
        border: 1px solid $BMGFblack !important;

        .css-1uccc91-singleValue {
          color: $BMGFblack !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $BMGFblack !important;
        }

        svg {
          color: $BMGFblack !important;
        }

        .react-select__menu-list {
          .react-select__option {

       
              background-color: $BMGFblack !important;
              color: $purewhite;
      
            &:hover {
              background-color: $BMGF300;
            }
          }
        }
      }
    }

    .support {
      color: $BMGFblack !important;

      .feedback-link {
        color: $BMGFblack !important;
        &:hover {
          color: $purewhite !important;
        }
      }

      &:hover {
        background-color: $BMGF300 !important;
        color: $purewhite !important;
      }
    }

    button {
      color: $BMGFblack !important;

      &:hover {
        background-color: $BMGF300 !important;
        color: $purewhite !important;
      }
    }
  }

  //SIDE NAV----------------------------
  .side-workspace-nav {
    .nav-container {
      button {
        color: $BMGFblack;

        svg {
          color: $BMGFblack;
        }
      }

      .expand-icon {
        color: $BMGFblack !important;
      }

      .sidebar-expand {
        &:active {
          color: $BMGFblack !important;
        }
      }

      .sidenav-link {
        color: $BMGFblack;

        &:hover {
          background-color: $BMGF300;
          color: $purewhite;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $BMGFblack;
          color: $purewhite;
          svg {
            color: $purewhite !important;
          }
        }
      }

      .support-request {
        border-top: solid $BMGFblack 1px;
        .support-row {
          color: $BMGFblack;

          &:hover {
            background-color: $BMGF300;
            color: $purewhite;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $BMGFblack !important;
    }

    .sidenav-link {
      color: $BMGFblack !important;

      &:hover {
        background-color: $BMGF300 !important;
        color: $purewhite !important;
      }
    }

    .support-request {
      .support-link {
        color: $BMGFblack;

        &:hover {
          background-color: $BMGF300;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $BMGFblack;
      }

      button {
        color: $BMGFblack;
      }

      svg {
        color: $BMGFblack !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $BMGF700 !important;
    }
  }

  .preview-link {
    color: $BMGF700;
  }

  .page-item {
    .page-link {
      color: $BMGF700;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $BMGF700;
      border: none;
      color: $purewhite;
    }
  }

  span.report-num {
    color: $BMGF700 !important;
  }

  .clear-filter-btn {
    color: $BMGF500 !important;
  }

  .tertiary-button {
    background-color: $BMGF700 !important;
    color: $purewhite;
    border: 1px solid $BMGF700;
    &:hover {
      background-color: $BMGF700;
      color: $purewhite;
      border: 1px solid $BMGF700;
    }
  }
  
  .secondary-link {
    color: $BMGF700 !important;
    background-color: $purewhite;
    border: 1px solid $purewhite;
    &:hover {
      background-color: $purewhite;
      color: $BMGF700 !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $BMGF700 !important;
  }

  .add-new-report {
    &:hover {
      background-color: $BMGF700;
    }
  }

  .button-cancel {
    color: $BMGF500 !important;
  }
    
  input[type="checkbox"] {
    accent-color: $BMGF700 !important;
  }

}

//GHMH GLOBAL HEALTH MARKETS HUB------------------------GHMH----------------------GHMH---------------------GHMH-----------------GHMH-----

.Public-Access {
  .tenant-top-nav {

    div.search-col.col-xl-8.col-lg-8.col-md-11.col-11 {
      background-color: $GHMH500;
    }

    div.signin-col.col-xl-4.col-lg-4.col-md-1.col-1 {
      background-color: $GHMH500;

      .btn-primary {
        background-color: $GHMH500 !important;
      }
    }
  }

  .tenant-info {
    color: $GHMH500 !important;

    h5 {
      color: $GHMH500 !important;
    }

    p {
      color: $GHMH500 !important;
      border-bottom: 1px solid $GHMH500 !important;
    }
  }

  .setting-feedback-login {

    // tenant selector styles
    .tenant-select {
      .css-yk16xz-control {
        background-color: $blackwhite50 !important;
        border: 1px solid $GHMH500 !important;

        .css-1uccc91-singleValue {
          color: $GHMH500 !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $GHMH500 !important;
        }

        svg {
          color: $GHMH500 !important;
        }

        .react-select__menu-list {
          .react-select__option {

       
              background-color: $GHMH500 !important;
              color: $purewhite;
      
            &:hover {
              background-color: $GHMH300;
            }
          }
        }
      }
    }

    .support {
      color: $GHMH500 !important;

      .feedback-link {
        color: $GHMH500 !important;
        &:hover {
          color: $purewhite !important;
        }
      }

      &:hover {
        background-color: $GHMH300 !important;
        color: $purewhite !important;
      }
    }

    button {
      color: $GHMH500 !important;

      &:hover {
        background-color: $GHMH300 !important;
        color: $purewhite !important;
      }
    }
  }

  //SIDE NAV----------------------------
  .side-workspace-nav {
    .nav-container {
      button {
        color: $GHMH500;

        svg {
          color: $GHMH500;
        }
      }

      .expand-icon {
        color: $GHMH500 !important;
      }

      .sidebar-expand {
        &:active {
          color: $GHMH500 !important;
        }
      }

      .sidenav-link {
        color: $GHMH500;

        &:hover {
          background-color: $GHMH300;
          color: $purewhite;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $GHMH500;
          color: $purewhite;
        }
      }

      .support-request {
        border-top: solid $GHMH500 1px;
        .support-row {
          color: $GHMH500;

          &:hover {
            background-color: $GHMH300;
            color: $purewhite;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $GHMH500 !important;
    }

    .sidenav-link {
      color: $GHMH500 !important;

      &:hover {
        background-color: $GHMH300 !important;
        color: $purewhite !important;
      }
    }

    .support-request {
      .support-link {
        color: $GHMH500;

        &:hover {
          background-color: $GHMH300;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $GHMH500;
      }

      button {
        color: $GHMH500;
      }

      svg {
        color: $GHMH500 !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $GHMH700 !important;
    }
  }

  .preview-link {
    color: $GHMH700;
  }

  .page-item {
    .page-link {
      color: $GHMH700;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $GHMH700;
      color: $purewhite;
      border: none;
    }
  }

  span.report-num {
    color: $GHMH700 !important;
  }

  .clear-filter-btn {
    color: $GHMH500 !important;
  }

  .tertiary-button {
    background-color: $GHMH700 !important;
    color: $purewhite;
    border: 1px solid $GHMH700;
    &:hover {
      background-color: $GHMH700;
      color: $purewhite;
      border: 1px solid $GHMH700;
    }
  }
  
  .secondary-link {
    color: $GHMH700 !important;
    background-color: $purewhite;
    border: 1px solid $purewhite;
    &:hover {
      background-color: $purewhite;
      color: $GHMH700 !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $GHMH700 !important;
  }

  .add-new-report {
    &:hover {
      background-color: $GHMH700;
    }
  }

  .button-cancel {
    color: $GHMH500 !important;
  }
    
  input[type="checkbox"] {
    accent-color: $GHMH700 !important;
  }

}

//CHAI WORKSPACE CUSTOM COLORS -----------------------------------------------------CHAI WORKSPACE CUSTOM COLORS -----------------------------------------------------CHAI WORKSPACE CUSTOM COLORS -----------------------------------------------------
.chai {

  .tenant-top-nav {
    @media (max-width: 1399px) {
      .search-col {
        padding-block-start: 2px;
      }
    }
    div.search-col.col-xl-8.col-lg-8.col-md-11.col-11 {
      background-color: $CHAIprimary500;
    }

    div.signin-col.col-xl-4.col-lg-4.col-md-1.col-1 {
      background-color: $CHAIprimary500;
      .btn-primary {
        background-color: $CHAIprimary500 !important;
      }
    }
  }

  .tenant-info {
    color: $CHAIprimary500 !important;

    h5 {
      color: $CHAIprimary500 !important;
    }

    p {
      color: $CHAIprimary500 !important;
      border-bottom: 1px solid $CHAIprimary500 !important;
    }
  }

  .setting-feedback-login {

    // tenant selector styles
    .tenant-select {
      .css-yk16xz-control {
        background-color: $blackwhite50 !important;
        border: 1px solid $CHAIprimary500 !important;

        .css-1uccc91-singleValue {
          color: $CHAIprimary500 !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $CHAIprimary500 !important;
        }

        svg {
          color: $CHAIprimary500 !important;
        }
      }
    }

    .support {
      color: $CHAIprimary500 !important;

      .feedback-link {
        color: $CHAIprimary500 !important;
        &:hover {
          color: $purewhite !important;
        }
      }

      &:hover {
        background-color: $CHAIprimary300 !important;
        color: $purewhite !important;
      }
    }

    button {
      color: $CHAIprimary500 !important;

      &:hover {
        background-color: $CHAIprimary300 !important;
        color: $purewhite !important;
      }
    }
  }

  //SIDE NAV----------------------------
  .side-workspace-nav {
    .nav-container {
      button {
        color: $CHAIprimary500;

        svg {
          color: $CHAIprimary500;
        }
      }

      .expand-icon {
        color: $CHAIprimary500 !important;
      }

      .sidebar-expand {
        &:active {
          color: $CHAIprimary500 !important;
        }
      }

      .sidenav-link {
        color: $CHAIprimary500;

        &:hover {
          background-color: $CHAIprimary300;
          color: $purewhite;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $CHAIprimary500;
          color: $purewhite;
        }
      }

      .support-request {
        border-top: solid $CHAIprimary500 1px;
        .support-row {
          color: $CHAIprimary500;
          &:hover {
            background-color: $CHAIprimary300;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $CHAIprimary500 !important;
    }

    .sidenav-link {
      color: $CHAIprimary500 !important;

      &:hover {
        background-color: $CHAIprimary300 !important;
        color: $purewhite !important;
      }
    }

    .support-request {
      .support-link {
        color: $CHAIprimary500;

        &:hover {
          background-color: $CHAIprimary300;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $CHAIprimary500;
      }

      button {
        color: $CHAIprimary500;
      }

      svg {
        color: $CHAIprimary500 !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $CHAIprimary500 !important;
    }
  }

  .preview-link {
    color: $CHAIprimary500;
  }

  .page-item {
    .page-link {
      color: $CHAIprimary500;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $CHAIprimary500;
      border: none;
      color: $purewhite;
    }
  }

  span.report-num {
    color: $CHAIprimary500 !important;
  }

  .clear-filter-btn {
    color: $CHAIprimary500 !important;
  }

  .tertiary-button {
    background-color: $CHAIprimary500 !important;
    color: $purewhite;
    border: 1px solid $CHAIprimary500;
    &:hover {
      background-color: $CHAIprimary500;
      color: $purewhite;
      border: 1px solid $CHAIprimary500;
    }
  }
  
  .secondary-link {
    color: $CHAIprimary500 !important;
    background-color: $purewhite;
    border: 1px solid $purewhite;
    &:hover {
      background-color: $purewhite;
      color: $CHAIprimary500 !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $CHAIprimary500;
  }

  .add-new-report {
    &:hover {
      background-color: $CHAIprimary300;
    }
  }
  .button-cancel {
    color: $CHAIprimary500 !important;
  }

  input[type="checkbox"] {
    accent-color: $CHAIprimary500 !important;
  }
}

// CAMP Working Group Hub ------------------------------------------------------------------------------------CAMP Working Group Hub---------------------------------------------------------------------------------------------------------------- 
.camp-working-group-hub {
  .tenant-top-nav {

    div.search-col.col-xl-8.col-lg-8.col-md-11.col-11 {
      background-color: $GHMH500;
    }

    div.signin-col.col-xl-4.col-lg-4.col-md-1.col-1 {
      background-color: $GHMH500;

      .btn-primary {
        background-color: $GHMH500 !important;
      }
    }
  }

  .tenant-info {
    color: $GHMH500 !important;

    h5 {
      color: $GHMH500 !important;
    }

    p {
      color: $GHMH500 !important;
      border-bottom: 1px solid $GHMH500 !important;
    }
  }

  .setting-feedback-login {

    // tenant selector styles
    .tenant-select {
      .css-yk16xz-control {
        background-color: $blackwhite50 !important;
        border: 1px solid $GHMH500 !important;

        .css-1uccc91-singleValue {
          color: $GHMH500 !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $GHMH500 !important;
        }

        svg {
          color: $GHMH500 !important;
        }

        .react-select__menu-list {
          .react-select__option {

       
              background-color: $GHMH500 !important;
              color: $purewhite;
      
            &:hover {
              background-color: $GHMH300;
            }
          }
        }
      }
    }

    .support {
      color: $GHMH500 !important;

      .feedback-link {
        color: $GHMH500 !important;
        &:hover {
          color: $purewhite !important;
        }
      }

      &:hover {
        background-color: $GHMH300 !important;
        color: $purewhite !important;
      }
    }

    button {
      color: $GHMH500 !important;

      &:hover {
        background-color: $GHMH300 !important;
        color: $purewhite !important;
      }
    }
  }

  //SIDE NAV----------------------------
  .side-workspace-nav {
    .nav-container {
      button {
        color: $GHMH500;

        svg {
          color: $GHMH500;
        }
      }

      .expand-icon {
        color: $GHMH500 !important;
      }

      .sidebar-expand {
        &:active {
          color: $GHMH500 !important;
        }
      }

      .sidenav-link {
        color: $GHMH500;
        svg {
          color: $GHMH500 !important;
        }

        &:hover {
          background-color: $GHMH300;
          color: $purewhite;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $GHMH500;
          color: $purewhite;
          svg {
            color: $purewhite !important;
          }
        }
      }

      .support-request {
        border-top: solid $GHMH500 1px;
        .support-row {
          color: $GHMH500;

          svg {
            color: $GHMH500 !important;
          }

          &:hover {
            background-color: $GHMH300;
            color: $purewhite;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $GHMH500 !important;
    }

    .sidenav-link {
      color: $GHMH500 !important;

      &:hover {
        background-color: $GHMH300 !important;
        color: $purewhite !important;
      }
    }

    .support-request {
      .support-link {
        color: $GHMH500;

        &:hover {
          background-color: $GHMH300;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $GHMH500;
      }

      button {
        color: $GHMH500;
      }

      svg {
        color: $GHMH500 !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $GHMH700 !important;
    }
  }

  .preview-link {
    color: $GHMH700;
  }

  .page-item {
    .page-link {
      color: $GHMH700;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $GHMH700;
      color: $purewhite;
      border: none;
    }
  }

  span.report-num {
    color: $GHMH700 !important;
  }

  .clear-filter-btn {
    color: $GHMH500 !important;
  }

  .tertiary-button {
    background-color: $GHMH700 !important;
    color: $purewhite;
    border: 1px solid $GHMH700;
    &:hover {
      background-color: $GHMH700;
      color: $purewhite;
      border: 1px solid $GHMH700;
    }
  }
  
  .secondary-link {
    color: $GHMH700 !important;
    background-color: $purewhite;
    border: 1px solid $purewhite;
    &:hover {
      background-color: $purewhite;
      color: $GHMH700 !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $GHMH700 !important;
  }

  .add-new-report {
    &:hover {
      background-color: $GHMH700;
    }
  }

  .button-cancel {
    color: $GHMH500 !important;
  }
    
  input[type="checkbox"] {
    accent-color: $GHMH700 !important;
  }

}




// HCIH / CE / Campaign Effectiveness ------------------------------------------------------------------------------------CAMP Working Group Hub---------------------------------------------------------------------------------------------------------------- 
.campaign-effectiveness {
  .tenant-top-nav {

    div.search-col.col-xl-8.col-lg-8.col-md-11.col-11 {
      background-color: $HCIH500;
    }

    div.signin-col.col-xl-4.col-lg-4.col-md-1.col-1 {
      background-color: $HCIH500;

      .btn-primary {
        background-color: $HCIH500 !important;
      }
    }
  }

  .tenant-info {
    color: $HCIH500 !important;

    h5 {
      color: $HCIH500 !important;
    }

    p {
      color: $HCIH500 !important;
      border-bottom: 1px solid $HCIH500 !important;
    }
  }

  .setting-feedback-login {

    // tenant selector styles
    .tenant-select {
      .css-yk16xz-control {
        background-color: $blackwhite50 !important;
        border: 1px solid $HCIH500 !important;

        .css-1uccc91-singleValue {
          color: $HCIH500 !important;
        }

        .css-1okebmr-indicatorSeparator {
          border: 1px solid $HCIH500 !important;
        }

        svg {
          color: $HCIH500 !important;
        }

        .react-select__menu-list {
          .react-select__option {

       
              background-color: $HCIH500 !important;
              color: $purewhite;
      
            &:hover {
              background-color: $HCIH300;
            }
          }
        }
      }
    }

    .support {
      color: $HCIH500 !important;

      .feedback-link {
        color: $HCIH500 !important;
        &:hover {
          color: $purewhite !important;
        }
      }

      &:hover {
        background-color: $HCIH300 !important;
        color: $purewhite !important;
      }
    }

    button {
      color: $HCIH500 !important;

      &:hover {
        background-color: $HCIH300 !important;
        color: $purewhite !important;
      }
    }
  }

  //SIDE NAV----------------------------
  .side-workspace-nav {
    .nav-container {
      button {
        color: $HCIH500;

        svg {
          color: $HCIH500;
        }
      }

      .expand-icon {
        color: $HCIH500 !important;
      }

      .sidebar-expand {
        &:active {
          color: $HCIH500 !important;
        }
      }

      .sidenav-link {
        color: $HCIH500;
        svg {
          color: $HCIH500 !important;
        }

        &:hover {
          background-color: $HCIH300;
          color: $purewhite !important;
        }
      }

      .app-link-list {
        .active-tenant {
          background-color: $HCIH500;
          color: $purewhite;
          svg {
            color: $purewhite !important;
          }
        }
      }

      .support-request {
        border-top: solid $HCIH500 1px;
        .support-row {
          color: $HCIH500;

          svg {
            color: $HCIH500 !important;
          }

          &:hover {
            background-color: $HCIH300;
            color: $purewhite;
          }
        }
      }
    }
  }

  .top-workspace-nav {
    .active-tenant {
      background-color: $HCIH500 !important;
    }

    .sidenav-link {
      color: $HCIH500 !important;

      &:hover {
        background-color: $HCIH300 !important;
        color: $purewhite !important;
      }
    }

    .support-request {
      .support-link {
        color: $HCIH500;

        &:hover {
          background-color: $HCIH300;
        }
      }
    }

    .close-menu-row {
      .close-menu {
        color: $HCIH500;
      }

      button {
        color: $HCIH500;
      }

      svg {
        color: $HCIH500 !important;
      }
    }
  }

  .story-teaser {
    h5 {
      color: $HCIH700 !important;
    }
  }

 
  a {
    text-decoration: none;
    color: $HCIH500;
     // news preview links
   .add-pointer.h2 {
        color: $HCIH500 !important;
        text-decoration: none !important;
    }
  }

  .preview-link {
    color: $HCIH700;
  }

  .page-item {
    .page-link {
      color: $HCIH700;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $HCIH700;
      color: $purewhite;
      border: none;
    }
  }

  span.report-num {
    color: $HCIH700 !important;
  }

  .clear-filter-btn {
    color: $HCIH500 !important;
  }

  .tertiary-button {
    background-color: $HCIH700 !important;
    color: $purewhite;
    border: 1px solid $HCIH700;
    &:hover {
      background-color: $HCIH700;
      color: $purewhite;
      border: 1px solid $HCIH700;
    }
  }
  
  .secondary-link {
    color: $HCIH700 !important;
    background-color: $purewhite;
    border: 1px solid $purewhite;
    &:hover {
      background-color: $purewhite;
      color: $HCIH700 !important;
      text-decoration: none !important;
    }
  }
  
  .secondary-link-plain {
    color: $HCIH700 !important;
  }

  .add-new-report {
    &:hover {
      background-color: $HCIH700;
    }
  }

  .button-cancel {
    color: $HCIH500 !important;
  }
    
  input[type="checkbox"] {
    accent-color: $HCIH700 !important;
  }

   .article > table > tbody > tr > td > span{
      color: $purewhite !important;
      background-color: #236fa1 !important; 
      border-color: #236fa1;
  }

}


//Media Queries----------------Media Queries---------------Media Queries------------------Media Queries--------------Media Queries-------

@media (max-width: 991px) {

  .tenant-top-nav {

    .tenant-name {
      display: none;
    }
  }
}