@import '../utilities/variable.scss';
@import '../utilities/variable_classes';
@import '../utilities/mixin.scss';


// ALCHEMY STYLES-----------------------------------------------------------------
.alc-app {



//---Organization editor styles--------------------------------------------------------------
.organization-details {
  min-width: 70%;
}

//---Drug editor styles--------------------------------------------------------------

.drug-editor {
  max-width: 80vw;
  margin-left: 10vw;

  .check-drug-btn {
    margin-left: 4px;
  }
}

//---indication editor styles-------------------------------------------------------

.indication-editor {
  .btn {
    margin: 16px 4px 0 0;
  }
}

//---Basic styles--------------------------------------------------------------------
.modal-dialog.modal-90w {
  min-width: 95vw;
}

.modal

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number]{
  width:100%;
}

.padded-row {
  padding-top: 10px;
}

.extra-padded-row {
  padding-top: 30px;
}


.select-text {
  min-width: 150px;
}

.empty-select-space { // holds empty space in case of conditional rendering
  height: 86px;
}

.select {
  @include bodySmall();
  width:100%;
  padding-top:0;
  padding-bottom:0;
}

.select-sm{
  @include bodySmall();
  width: 80px;
}

.select-md{
  @include bodySmall();
  width: 100px;
}

.select-lg {
  @include bodySmall();
  min-width: 300px;
  padding: 0pt;
}

.select-xlg {
  @include bodySmall();
  min-width: 500px;
  padding: 0pt;
}

.select-max-100 {
  max-width: 100%;
}

.basic-text{
  @include bodySmall();
  margin-block-end: $spacing03;
}

.input-text{
  padding-block-start: $spacing06;
  padding-block-end: $spacing03;
  @include bodySmall();
}

.css-g1d714-ValueContainer {
  padding-inline-start: 0;
  .css-1oscnvr-placeholder { //header dropdown filters
    margin-inline-start: 0;
    @include body-medium-condensed();
  }
}

.css-1wa3eu0-placeholder { // select.. placeholder
  @include bodySmall();
}

.nav-card{
  background-color: rgb(222, 219, 219);
}

.nav-card-with-margin{
  margin-top: 20px;
}

.bottom-margin{
  margin-bottom: 50px;
}

.css-1uccc91-singleValue {
  font-family: $openSans;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem; //14px
  line-height: 1rem; //16px
}

.css-12jo7m5 { // multi-select
  @include bodySmall();
}

.scrollable{
  overflow-x:scroll;
  max-height: 90vh;
}

.table-form-control {
  min-width: 60px;
  font-size:small;
}


.form-ctrl{
  @include bodySmall();
  height:38px;
  border-width: 1px;
  border-color: #D3D3D3;
  vertical-align: middle;
  padding:10px;
  width:100%;
  border-style:solid;
  border-radius: 5px;
}


.ad-hoc{
  @extend .form-ctrl;
  color:green;
}

.override{
  @extend .form-ctrl;
  color:red;
}


.heading{
  @include h2();
  color: #000000;
}



.table-heading {
  th {
    background-color: $blackwhite100 !important;
    strong {
      background-color: $blackwhite100 !important;
    }
  }
}

.form-text{
  @include subtitle1();
}

.success {
  @extend .form-text;
  color: green;
}

.error {
  @extend .form-text;
  color: red;
}

.padding-0{
  padding-right:0;
  padding-left:0;
}

.deleted {
  color: red !important;
  text-decoration: line-through;
}


.table {
  table-layout:auto;
  //table-layout: fixed; 
  margin-bottom:20px;
  margin-block-start: 12px;
  tr {
    margin-top: 10px;
    min-width: 20%;
    @include bodySmall();
    font-weight: 500;
  }

  th {
    margin-top: 10px;
    min-width: 90px; // allows enough space for large numbers like 10,675,465
    padding-left:0.7rem;
    @include h6();
    font-weight: 500;
    vertical-align: text-top;
    border:none;
  }
  td {
    word-wrap: break-word;
    margin: 0;
    padding: 0.5rem;
    min-width: 80px; // ensures year columns align even without data


    @include bodySmall();
    :last-child {
      border-right: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.right-align{
  text-align: right;
}

.end-align {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.textarea {
  @extend .form-ctrl;
  max-width: 100%;
  //min-height:75px;
}

.full-width {
  width: 100%;
}


/* Default styles for DateSelector columns */
.date-selector-column {
  padding-inline-end: $spacing02;
}

// ----------------------------------------------------------------------------------------------------

.min-width-220 {
  min-width: 220px; 
}

.min-width-300 {
  min-width: 300px; 
}

.auto-width {
  width: auto;
}

.expand-title {
  margin-inline-end: $spacing04;
  .button-secondary {
    margin-inline-start: $spacing06;
  }
}

.ai-picture{
  max-width:800px;
}

.clickable-text {
	color: blue;
  font-size: 0.75rem;
  text-decoration: underline;
  text-align: right;
  padding-right: 3px;
  padding-left: 3px;
  background:transparent;
  vertical-align: text-top;
  border: none !important;
	&:hover {
		color: #ed1212;
		cursor: pointer;
	}
}

.tab-content {

  .tab-pane {
   
  
    .capacity {
      padding: 20px;
    
      .col {
        padding-left: 0;
      }
    
      .col-left {
        padding: 0;
    
        h5 {
          @include bodyMedium();
          font-weight: 500;
          margin-bottom: 0;
        }
      }
  
      .capacity-table {
        overflow: scroll;
        overflow-y: hidden;
  
        td {
          min-width: 175px;
        }
      }
    
      .col-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0;
    
        .data-check {
          max-width: 81px;
          margin-right: 12px;
        }
    
        .low-check {
          max-width: 76px;
          margin-right: 12px;
        }
    
        .high-check {
          max-width: 80px;
        }
      }
    
      .probability-thead {
        width: 490px;
        margin-top: 0;
        margin-left: 20px;
      }
    
    }
  }
}

.alchemy-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-block-start: $spacing03;
}

// Settings Panel ----------------------------Settings Panel--------------------------------------------------




.settings-popover {

  .settings-content {
    padding: $spacing06;
    border-radius: 0 !important;
    max-width: 100%; 
    box-sizing: border-box; 
    
    .settings-header {
      position: relative;
      border-bottom: none;
      @include bodyLarge;
      padding-inline-end: $spacing02;
      display: flex;
      justify-content: space-between;

      svg {
        margin-inline-start: $spacing03;
        margin-inline-end: $spacing03;
        height: 16px;
        width: 16px;
      }
    }

    .settings-body {
      @include bodyLarge;
      margin-inline-start: 0;
    }

    .settings-footer {
      display: flex;       
      justify-content: flex-end; 
      padding: $spacing03;
      border-top: none;

      .row {
        .close {
          padding-inline-end: $spacing03;
        }
        .confirm {
          padding-inline-start: $spacing02;
        }
        margin: 0;
      }
    }
  }
}

.btn-toolbar {
  margin-block-start: $spacing03;
}

.editor-btn {
  margin-block-start: $spacing03;
  float: right;
}


  

.alc-product-modal {
  padding: 20px !important;
  min-width: 80%;



  .modal-title-group {
    margin-block-end: $spacing07; // 28px
  .product-name-header {
    margin-block-end: $spacing03; // 12px
    @include h3();
    font-weight: 500;
    span {
      font-weight: 400 !important;
      text-transform: uppercase;
    }
  }
  
  
  .sub-header {
    margin-block-end: $spacing03;
    @include h3();
    font-weight: 500;
    span {
      font-weight: 400 !important;
    }
  }
  }
  .nav-item {
    padding: 10px 20px;
  }
}


// New styles




.search-table {
  width: 100%;
  overflow: auto;
  margin-bottom:20px;
  border-collapse: collapse;
}

.search-table-dynamic {
  @extend .search-table;
  display: grid;
}

.search-table-tr {
  margin-top: 10px;
  min-width: 20%;
  @include label-medium();
}

.search-table-tr-dynamic {
  @extend .search-table-tr;
  display: contents;
}


.search-table-thead,
.search-table-tbody {
  margin-top: 10px;
  min-width: 20%;
  @include label-medium();
  font-weight: $font-weight-bold;
}


.search-table-thead-dynamic,
.search-table-tbody-dynamic {
  @extend .search-table-thead;
  display: contents;
}

.search-table-th{
  padding: 0.5rem;
  background-color: $surface-primary-light !important;
  position: relative;
  margin-top: 10px;
  min-width: 75px;
  @include label-medium();
  font-weight: $font-weight-bold;
  vertical-align: text-top;
}

.search-table-td {
  margin: 0;
  padding: 0.75rem !important;
  overflow: hidden;
  @include label-medium();
  border-bottom: 1px solid lightgray;
  :last-child {
    border-right: 0;
  }
  &:hover {
    cursor: pointer;
  }
}

.deleted-search-table-td {
  @extend .search-table-td;
  color:red !important;
  text-decoration: line-through;
}
.css-2b097c-container {
  @include bodySmall();
}


.css-180gzrl-indicatorContainer { // chevrondown
  svg {
    width: 16px;
  }
}
.search-table-span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  @include label-medium();
  font-weight: $font-weight-bold;
}


.table-wrapper {
  margin-bottom: 25px;
  border-radius: 6px;
  background: #fff;
  overflow: hidden; 
}

// for resizing
.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;
}

.resize-handle:hover {
  border-color: #ccc;
}

.resize-handle.active {
  border-color: #517ea5;
}

@media (max-width: 1200px) {
  .show-scroll {
    overflow-x: scroll;
  }

}
}
